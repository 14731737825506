<div id="fb-root"></div>
<script
  async
  defer
  crossorigin="anonymous"
  src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0"
  nonce="HmaXjWan"
></script>
<div
  class="fb-page"
  data-href="https://www.facebook.com/szczepwodnybaltyk"
  data-width="340"
  data-hide-cover="false"
  data-show-facepile="true"
></div>
<h1 class="tw" #tw></h1>

<div>
  <div>
    <div class="highlight-phone">
      <div class="waveWrapper waveAnimation">
        <div class="waveWrapperInner bgTop">
          <div
            class="wave waveTop"
            style="
              background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png');
            "
          ></div>
        </div>
        <div class="waveWrapperInner bgMiddle">
          <div
            class="wave waveMiddle"
            style="
              background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png');
            "
          ></div>
        </div>
        <div class="waveWrapperInner bgBottom">
          <div
            class="wave waveBottom"
            style="
              background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png');
            "
          ></div>
        </div>
      </div>

      <!-- <div class="gradient-border" id="box">
        <video autoplay muted loop id="myVideo"  style="width: 100%; height: 20%; position: relative; margin: 0;">
          <source src="assets/img/video-background-main-cropped3.mp4" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
      </div> -->

      <div class="container">
        <div class="title-container" style="z-index: 99">
          <div class="row fadein">
            <div class="col" style="display: contents; align-items: flex-end">
              <h1 class="date">
                <span class="badge badge-pill badge-dark">ON, Canada</span>
              </h1>
              <!-- <h1 class="title" style="font-size : 4em;  align-self: center; margin-top: 3%;">Szczep</h1><br> -->
              <h1 class="title" #title></h1>
            </div>
          </div>
          <div class="row">
            <!-- <img src = "../../../assets/img/title.png" style = "max-width: 100%;"> -->
            <!-- <h1 style = "color: black; text-align: center;">SZCZEP WODNY BALTYK</h1> -->

            <div class="col-md-6">
              <div class="intro fadein" style="margin-top: 5%">
                <!-- <img src = "../../../assets/img/character.png" style = "max-width: 90%;"> -->
                <h3 class="fadein info">
                  Kliknij tutaj, aby dowiedzieć się więcej o naszym Szczepie!
                </h3>
                <a
                  class="btn btn-dark fadein black_button"
                  role="button"
                  (click)="navigateToOgolne()"
                >
                  Dowiedz się więcej
                </a>
              </div>
            </div>
            <!-- <div>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSzczepWodnyBaltyk%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="340" height="550" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> -->
          </div>

          <div class="container marketing" style="margin-top: 10%">
            <!-- Three columns of text below the carousel -->
            <div class="row circle">
              <div class="col-sm-4">
                <img
                  class="rounded-circle"
                  width="140"
                  height="140"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 140x140"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  src="assets/img/square_img_1.JPG"
                />

                <h2>Ogólne</h2>
                <p>Ogólne informacje o szczepie</p>
                <p>
                  <a
                    class="btn btn-dark black_button"
                    (click)="navigateToOgolne()"
                    >Detale</a
                  >
                </p>
              </div>
              <!-- /.col-lg-4 -->
              <div class="col-sm-4">
                <img
                  class="rounded-circle"
                  width="140"
                  height="140"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 140x140"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  src="assets/img/square_img_2.JPG"
                />

                <h2>Zbiórki</h2>
                <p>Lokalizacja zbiórek</p>
                <p>
                  <a
                    class="btn btn-dark black_button"
                    (click)="navigateToZbiorki()"
                    >Detale</a
                  >
                </p>
              </div>
              <!-- /.col-lg-4 -->
              <div class="col-sm-4">
                <img
                  class="rounded-circle"
                  width="140"
                  height="140"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 140x140"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  src="assets/img/square_img_3.JPG"
                />

                <h2>Kontakt</h2>
                <p>Skontaktuj się z kimś z naszego szczepu</p>
                <p>
                  <a
                    class="btn btn-dark black_button"
                    (click)="navigateToKontakt()"
                    >Detale</a
                  >
                </p>
              </div>
              <!-- /.col-lg-4 -->
            </div>
            <!-- /.row -->

            <!-- START THE FEATURETTES -->

            <hr class="featurette-divider" />

            <div class="row featurette">
              <div class="col-md-7">
                <h2 class="featurette-heading">
                  Zuchy - <span class="text-muted">Morskie Lwy</span>
                </h2>
                <p class="lead">
                  Gromada zuchowa dla dzieci w wieku 7-10 lat. Zbiorki zuchowe
                  odbywają się we wtorki od 18:00 do 20:00, w Willow Way Public
                  School (adres 1715 Willow Way, Mississauga, ON L5M 3W5).
                </p>
              </div>
              <div class="col-md-5">
                <img
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  src="assets/img/sub1m.jpg"
                />
              </div>
            </div>

            <hr class="featurette-divider" />

            <div class="row featurette">
              <div class="col-md-7 order-md-2">
                <h2 class="featurette-heading">
                  Młodsi Harcerze -
                  <span class="text-muted"> Drużyna 35-ta</span>
                </h2>
                <p class="lead">
                  35ta Żeglarska Drużyna Harcerska im. Zygmunta Augusta dla
                  młodszych harcerzy (w wieku 11-13). Zbiorki odbywają się we
                  wtorki od 18:00 do 20:00, w Our Lady of Good Voyage Catholic
                  Elementary School (adres 5850 River Grove Ave, Mississauga, ON
                  L5M 4W2).
                </p>
              </div>
              <div class="col-md-5 order-md-1">
                <img
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  src="assets/img/sub5m.jpg"
                />
              </div>
            </div>

            <hr class="featurette-divider" />

            <div class="row featurette">
              <div class="col-md-7">
                <h2 class="featurette-heading">
                  Starsi Harcerze -
                  <span class="text-muted"> Drużyna 16-ta</span>
                </h2>
                <p class="lead">
                  16ta Żeglarska Drużyna Harcerska im. Bolesława Chrobrego dla
                  starszych harcerzy (w wieku 14-16). Zbiorki odbywają się we
                  wtorki od 18:00 do 20:00, w Our Lady of Good Voyage Catholic
                  Elementary School (adres 5850 River Grove Ave, Mississauga, ON
                  L5M 4W2).
                </p>
              </div>
              <div class="col-md-5">
                <img
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  src="assets/img/sub2m.jpg"
                />
              </div>
            </div>
            <hr class="featurette-divider" />

            <div class="row featurette">
              <div class="col-md-7 order-md-2">
                <h2 class="featurette-heading">
                  Wędrownicy - <span class="text-muted">Drużyna 3cia</span>
                </h2>
                <p class="lead">
                  3cia Żeglarska Drużyna Wędrownicza im. Władysława Wagnera dla
                  wędrowników (w wieku 16+). Lokalizacje i detale zbiorek są
                  zależne od programu i uczestników w obecnym roku, i się często
                  zmieniają.
                </p>
              </div>
              <div class="col-md-5">
                <img
                  class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                  width="500"
                  height="500"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                  aria-label="Placeholder: 500x500"
                  preserveAspectRatio="xMidYMid slice"
                  focusable="false"
                  src="assets/img/sub3m.jpg"
                />
              </div>
            </div>

            <hr class="featurette-divider" />

            <!-- /END THE FEATURETTES -->
          </div>
          <!-- /.container -->
          <footer>
            <!-- Footer Elements -->
            <div class="container">
              <!-- Grid row-->
              <div class="row">
                <!-- Grid column -->
                <div class="col-md-12 py-5">
                  <div class="mb-5 flex-center"></div>
                </div>
                <!-- Grid column -->
              </div>
              <!-- Grid row-->
            </div>

            <!-- Copyright -->

            <!-- Copyright -->
            <div class="footer-copyright text-center py-3">
              © 2021 Copyright
              <a href="https://SzczepWodnyBaltyk.com"> SzczepWodnyBaltyk.com</a>
            </div>
          </footer>

          <script src="assets/js/jquery.min.js"></script>
          <script src="assets/bootstrap/js/bootstrap.min.js"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.8.2/js/lightbox.min.js"></script>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.3.1/js/swiper.jquery.min.js"></script>
          <script src="assets/js/Simple-Slider1.js"></script>
        </div>
      </div>
    </div>
  </div>
</div>
