import { Injectable, ɵSWITCH_COMPILE_INJECTABLE__POST_R3__ } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {schedule} from '../schedule';

import {Observable} from 'rxjs';
import { scheduleElement } from 'src/scheduleElement';
import {environment} from '../../environment'
const baseUrl = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  ///////////////////////////////////////////////////
  //Handling all things related to the schedules!!!//
  ///////////////////////////////////////////////////


  //Using the http client
  constructor(private http: HttpClient) {}

  //Declaring the request object
  private async request(method: string, url: string, data?: any) {
    console.log('request ' + JSON.stringify(data));
    const result = this.http.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: {

      }
    });
    return new Promise<any>((resolve, reject) => {
      result.subscribe(resolve as any, reject as any);
    });
  }

  //Getting all the schedules for a given user based on the input
  getSchedules(input): Observable<schedule[]>{
   //console.log(this.http.get(baseUrl+"/api/schedules/").subscribe(data => {console.log(data, "is the data ye")}))
    return this.http.get<schedule[]>(baseUrl+"/api/courses/schedules/"+input)
  }

  //Adding a course to a schedule
  addCourse(name:string, courseCode:string, subject:string){
   //console.log(this.http.get(baseUrl+"/api/schedules/").subscribe(data => {console.log(data, "is the data ye")}))
    return this.http.put(baseUrl+"/api/courses/populate_schedule/",{name: name, course: courseCode, subject:subject} )
  }

  //Adding a schedule to the database
  addSchedule(input){
   //console.log(this.http.get(baseUrl+"/api/schedules/").subscribe(data => {console.log(data, "is the data ye")}))
    return this.http.post<schedule>(baseUrl+"/api/courses/new_schedule/", input)
  }

  //Removign a schedule based on the name
  removeSchedule(scheduleName: string){
   //console.log(this.http.get(baseUrl+"/api/schedules/").subscribe(data => {console.log(data, "is the data ye")}))
    return this.http.delete(baseUrl+"/api/schedule/delete/"+scheduleName)
  }

  //Editing the schedule name based on the input
  editScheduleName(scheduleName:String, newScheduleName:string){
     //console.log(this.http.get(baseUrl+"/api/schedules/").subscribe(data => {console.log(data, "is the data ye")}))
     return this.http.put(baseUrl+"/api/schedule/updateName/",{scheduleName: scheduleName, newScheduleName: newScheduleName} )
  }


  //Changing if a given schedule is public
  editIsPublic(isPublic: Boolean, scheduleName:string){
    return this.http.put(baseUrl+"/api/schedule/udpateIsPublic/",{scheduleName: scheduleName, newIsPublic: isPublic} )

  }


  //Changing the description of a given schedule
  editDescription(newDescription: string, scheduleName:string){
    return this.http.put(baseUrl+"/api/schedule/updateDescription/",{scheduleName: scheduleName, newDescription: newDescription} )
  }

  //Updating the last time a certain schedule was changed
  updateLastEdit(scheduleName:String, time:string){
    return this.http.put(baseUrl+"/api/schedule/udpateLastEdit/",{scheduleName: scheduleName, newEditTime: time} )
 }



 ////////////////////////////////
 //Getting/changing policy data//
 ////////////////////////////////
 getPrivacyPolicy(){
  return this.http.get(baseUrl+"/admin/privacy_policy" )
}
createPrivacyPoliy(){
  return this.http.put(baseUrl+"/admin/privacy_policy/create",{policy:"placeholder"} )
}
editPrivacyPolicy(inputPolicy){
  return this.http.put(baseUrl+"/admin/privacy_policy/edit", {policy:inputPolicy})
}


getDMCA(){
  return this.http.get(baseUrl+"/admin/dmca" )
}
createDMCA(){
  return this.http.put(baseUrl+"/admin/dmca/create",{policy:"placeholder"} )
}
editDMCA(inputPolicy){
  return this.http.put(baseUrl+"/admin/dmca/edit", {policy:inputPolicy})
}

getAUP(){
  return this.http.get(baseUrl+"/admin/aup" )
}
createAUP(){
  return this.http.put(baseUrl+"/admin/aup/create",{policy:"placeholder"} )
}
editAUP(inputPolicy){
  return this.http.put(baseUrl+"/admin/aup/edit", {policy:inputPolicy})
}


  //Removing a coures from a scheule
  removeCourse(courseName:string, scheduleName:string){
    return this.http.delete<any[]>(baseUrl+"/api/schedule/removecourse/"+scheduleName+"/"+courseName)
  }

  //Getting a schedule based on the namee
  getScheduleByName(scheduleName: string){
    //console.log(this.http.get<scheduleElement[]>(baseUrl+"/api/schedule/courses/"+scheduleName).subscribe(data => {console.log(data, "is the data ye")}))
    return this.http.get<any[]>(baseUrl+"/api/schedule/courses/"+scheduleName)
   }

   //Removing all the schdueles
  removeAllSchedules(){
    //console.log(this.http.get(baseUrl+"/api/schedules/").subscribe(data => {console.log(data, "is the data ye")}))
     return this.http.delete(baseUrl+"/api/schedule/")
   }

   //Getting the general infor about the scheduels
   getGeneralInfo(){
    return this.http.get<any[]>(baseUrl+"/api/courses/schedulesinfo/")
   }

   //Getting the most recent scheule
   getMostRecent(){
    return this.http.get<any[]>(baseUrl+"/api/schedule/most_recent/")
   }


}
