<div class="team-grid" style="margin: 10% 20px 20px">
  <div
    class="container"
    data-aos="fade-up"
    data-aos-anchor-placement="center-center"
  >
    <div class="intro">
      <h2 class="text-center">Zbiórki</h2>
    </div>
  </div>
</div>

<hr style="width: 40%" />

<div>
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <h4 style="text-align: center">Zuchy - Morskie Lwy</h4>
        <p style="text-align: justify">
          Gromada zuchowa dla dzieci w wieku 7-10 lat. Zbiorki zuchowe odbywają
          się we wtorki od 18:00 do 20:00, w Willow Way Public School (adres
          1715 Willow Way, Mississauga, ON L5M 3W5).
        </p>
      </div>
      <div class="col-md-7" data-aos="zoom-out">
        <div style="width: 100%">
          <iframe
            width="100%"
            height="600"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?q=Willow%20Way%20Public%20School&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="margin-top: 20px">
  <div class="container">
    <div class="row">
      <div class="col-md-5" data-aos="zoom-out">
        <h4 style="text-align: center">Młodsi Harcerze - Drużyna 35-ta</h4>
        <p style="text-align: justify">
          35ta Żeglarska Drużyna Harcerska im. Zygmunta Augusta dla młodszych
          harcerzy (w wieku 11-13). Zbiorki odbywają się we wtorki od 18:00 do
          20:00, w Our Lady of Good Voyage Catholic Elementary School (adres
          5850 River Grove Ave, Mississauga, ON L5M 4W2).
        </p>
        <img
          class="d-block w-25"
          style="display: block; margin: 0 auto; border-radius: 5px"
          src="../assets/img/35ta.png"
        />
      </div>
      <div class="col-md-7 custom-container">
        <div style="width: 100%">
          <iframe
            width="100%"
            height="600"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?q=Our%20Lady%20of%20Good%20Voyage%20Catholic%20Elementary%20School&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="margin-top: 20px">
  <div class="container">
    <div class="row">
      <div class="col-md-5" data-aos="zoom-out">
        <h4 style="text-align: center">Starsi Harcerze - Drużyna 16-ta</h4>
        <p style="text-align: justify">
          16ta Żeglarska Drużyna Harcerska im. Bolesława Chrobrego dla starszych
          harcerzy (w wieku 14-16). Zbiorki odbywają się we wtorki od 18:00 do
          20:00, w Our Lady of Good Voyage Catholic Elementary School (adres
          5850 River Grove Ave, Mississauga, ON L5M 4W2).
        </p>
        <img
          class="d-block w-25"
          style="display: block; margin: 0 auto; border-radius: 5px"
          src="../assets/img/16sta.jpg"
        />
      </div>
      <div class="col-md-7 custom-container">
        <div style="width: 100%">
          <iframe
            width="100%"
            height="600"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?q=Our%20Lady%20of%20Good%20Voyage%20Catholic%20Elementary%20School&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
