import { Component, OnInit } from '@angular/core';
import {UserService} from "../../user.service";
import {ScheduleService} from "../../schedule.service";
import { updateReturn } from 'typescript';
import {environment} from "../../../../environment"


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {


  //Vars to handle each of the policies
  isAdmin;
  baseUrl = environment.backend_url;
  dmcaLink=  environment.backend_url+"/admin/dmca"
  uapLink=  environment.backend_url+"/admin/aup"

  policyExists = false;
  dmcaPolicyExists =false
  aupPolicyExists = false;

  currentPolicy;
  currentPolicyDMCA;
  currentPolicyAUP;

  newPolicy;
  newPolicyDMCA;
  newPolicyAUP;

  inputBox= false;
  DMCAinputBox = false;
  AUPinputBox= false;


  //Contructor
  constructor(private userService : UserService,
              private scheduleService: ScheduleService) { }


    //Getting the user input for the different polcieis
    handleNewPolicy(term: string): void {this.newPolicy = term.replace(/[<={}()>/\\]/gi, "")}
    handleNewDMCAPolicy(term: string): void {this.newPolicyDMCA = term.replace(/[<={}()>/\\]/gi, "")}
    handleNewAUPPolicy(term: string): void {this.newPolicyAUP = term.replace(/[<={}()>/\\]/gi, "")}


  //Determining if the user is an admin and getting the polcicies if available
  ngOnInit(): void {
    this.userService.isAdmin().subscribe((data) =>{
      console.log(data,"is the admin data")
      this.isAdmin = data;

    },
    error=>{console.log("This user is not an admin")})


    //Getting the privacy polciy
    this.scheduleService.getPrivacyPolicy().subscribe(policy=>{
      if(policy){
        console.log(policy, "has been returned from the policy")
        this.currentPolicy = policy
        this.policyExists = true;
      }
    } )

    //Getting the DMCA
    this.scheduleService.getDMCA().subscribe(policy=>{
      if(policy){
        console.log(policy, "has been returned from the policy")
        this.currentPolicyDMCA = policy
        this.dmcaPolicyExists = true;
      }
    } )

    //Gettign the AUP
    this.scheduleService.getAUP().subscribe(policy=>{
      if(policy){
        console.log(policy, "has been returned from the policy")
        this.currentPolicyAUP = policy
        this.aupPolicyExists = true;
      }
    } )
  }


////////////////////////////////////////////////////////////////////////
//Getting the input for the different policies



  //Privacy Policy
  showInputBox(){
    this.inputBox = true;
  }
  createPolicy(){
    this.scheduleService.createPrivacyPoliy().subscribe(policy=> this.currentPolicy = policy)
  }
  editPolicy(){
    this.inputBox = false;
    this.scheduleService.editPrivacyPolicy(this.newPolicy).subscribe(policy=> this.currentPolicy = policy)
  }


  //DMCA
  showDMCAInputBox(){
    this.DMCAinputBox = true;
  }
  createDMCA(){
    this.scheduleService.createDMCA().subscribe(policy=> this.currentPolicyDMCA = policy)
  }
  editDMCA(){
    this.DMCAinputBox = false;
    console.log('sending ', this.newPolicyDMCA)
    this.scheduleService.editDMCA(this.newPolicyDMCA).subscribe(policy=> this.currentPolicyDMCA = policy)
  }


  //AUP
  showAUPInputBox(){
    this.AUPinputBox = true;
  }
  createAUP(){
    this.scheduleService.createAUP().subscribe(policy=> this.currentPolicyAUP = policy)
  }
  editAUP(){
    this.AUPinputBox = false;
    this.scheduleService.editAUP(this.newPolicyAUP).subscribe(policy=> this.currentPolicyAUP = policy)
  }

}
