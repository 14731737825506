import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import Typewriter from 't-writer.js';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
})
export class HomepageComponent implements AfterViewInit {
  constructor(private router: Router) {}
  scrollDepth: String = '10';
  glowna: String;
  ogolneInformacje: String;
  zbiorki: String;
  kontakt: String;
  formy: String;
  name = 'Angular';

  @ViewChild('title') myDiv: ElementRef;

  ngAfterViewInit() {
    const target = this.myDiv.nativeElement;
    console.log(target, 'is the target');
    const writer = new Typewriter(target, {
      loop: false,
      animateCursor: false,
      typeColor: 'white',
      removeCursor: true,
      typeSpeed: 'random',
    });

    writer.type(`Szczep Wodny Bałtyk`).rest(500).start();
  }

  //Navigating to the privacy page
  navigateToZbiorki() {
    this.router.navigate(['zbiorki']);
  }

  navigateToOgolne() {
    this.router.navigate(['ogolne'], { skipLocationChange: true });
  }

  navigateToFormy() {
    this.router.navigate(['formy'], { skipLocationChange: true });
  }
  navigateToKontakt() {
    this.router.navigate(['kontakt'], { skipLocationChange: true });
  }
}
