
 <div class="gradient-border mobile-container" id="box">
  <video autoplay muted loop id="myVideo"  style="width: 100%; height: 20%; position: relative; margin: 0;">
    <source src="assets/img/video-background-main-cropped3.mp4" type="video/mp4">
    Your browser does not support HTML5 video.
  </video>
</div>


<div class="container mobile-container">
  <div class="title-container">
    <div class="row">
      <div class="col" style=" margin-top: 10%; margin-right: 5%;">
        <h1 class="title" style="font-size : 6em; text-align: center"> ODKRYJ NOWE HORYZONTY</h1>
      </div>
    </div>
  </div>
  <hr style="width:70%">

  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-3" data-aos="zoom-out">
          <h4 style="text-align: center;">Żeglowanie</h4>
          <img class="d-block w-100" style="display: block;
      margin: 0 auto; border-radius: 5px; " src="../assets/img/ogolne1.jpg">

          <p style="text-align: justify;"> Jako Szczep specjalizujący się w żeglarstwie, kładziemy duży nacisk na szkolenie i przygotowanie nowej generacji polskich żeglarzy. 
            Dysponujemy dużą ilością rozmaitego sprzętu pływającego, od Optymistów i Mirrorów, do Albacorów i katamaranów. 
            Harcerze, którzy dołączają do naszych szeregów uczą się nimi posługiwać i operować, a w starszym wieku, jak je reperować i przy okazji szkolić następną generacje żeglarzy. 

          </p>
        </div>

        <div class="col-md-3" data-aos="zoom-out">
          <h4 style="text-align: center;">Wycieczki, Biwaki, Kolonia i Obozy</h4>
          <img class="d-block w-100" style="display: block;
              margin: 0 auto; border-radius: 5px; " src="../assets/img/ogolne2.jpg">

          <p style="text-align: justify;"> Podczas roku, nasz Szczep przeprowadza różne wyjazdy dla zuchów, harcerzy i wędrowników, aby urozmaicić program i pozwolić naszym harcerzom zastosować zdobytą wiedzę lub sprawność w praktyczny sposób.
             Centralnym wydarzeniem każdego roku harcerskiego jest nasz trzy-tygodniowy obóz harcerski, w którym harcerze obozują na Kaszubach razem z harcerzami i harcerkami z całego Ontario. 
             Zuchy, z kolej, biorą udział w kolonii zuchowej, gdzie przez dwa tygodnie mogą się bawić i zdobywać sprawności na łonie natury. 

          </p>
        </div>
        <div class="col-md-3" data-aos="zoom-out">
          <h4 style="text-align: center;">Zbiórki</h4>
          <img class="d-block w-100" style="display: block;
              margin: 0 auto; border-radius: 5px; " src="../assets/img/ogolne3.jpg">

          <p style="text-align: justify;"> Zuchy i harcerze spotykają się na cotygodniowych zbiorkach przygotowanych przez zastępowych lub szóstkowych, i członków komendy. 
            Podczas zbiórek uczestnicy zdobywają sprawności, uczą się o różnych technikach harcerskich (jak np. pierwszej pomocy lub pionierki), uczą się i śpiewają piosenki polskie, zdobywają wiedzę o Polsce i konkurują w zajęciach sportowych. 
            Proszę się skontaktować ze Szczepowym Szczepu Wodnego Bałtyk co do czasów i lokalizacji zbiórek.

          </p>
        </div>
        <div class="col-md-3" data-aos="zoom-out">
          <h4 style="text-align: center;">Braterstwo Harcerskie
          </h4>
          <img class="d-block w-100" style="display: block;
              margin: 0 auto; border-radius: 5px; " src="../assets/img/ogolne4.jpg">

          <p style="text-align: justify;">Największą zaletą naszej organizacji jest tworzenie i utrwalanie poczucia braterstwa w naszych uczestnikach. 
            Zuchy, harcerze i wędrownicy, którzy dołączają do naszego Szczepu dołączają również do jednej ogromnej rodziny harcerskiej, złączonej na całym świecie tą samą ideologią i wiarą. 
            Przyjaźnie zawiązane na zbiórkach, biwakach, wycieczkach, obozach czy zlotach, zostają na cale życie: coś, o czym każdy z naszych starszych harcerzy lub instruktorów może wam opowiedzieć. 

          </p>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-8" data-aos="fade-right">
          <img class="d-block w-100" style="display: block;
              margin: 5px; border-radius: 5px; " src="../assets/img/ogolne5.jpg">

          <!-- <p style="text-align: justify;"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum."
          </p> -->
        </div>
        <div class="col-md-4" data-aos="fade-left"> <img class="d-block w-100" style="display: block;
              margin: 5px; border-radius: 5px;" src="../assets/img/ogolne6.jpg">
              <img class="d-block w-100" style="display: block;
              margin: 5px; border-radius: 5px;;" src="../assets/img/ogolne9.jpg">
          <!-- <p style="text-align: justify;"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum."
          </p> -->
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="container">

      <div class="row">
        <div class="col-md-6" data-aos="fade-right"> <img class="d-block w-100" style="display: block;
            margin: 5px; border-radius: 5px; " src="../assets/img/ogolne7.jpg">

          <!-- <p style="text-align: justify;"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum."
          </p> -->
        </div>
        <div class="col-md-6" data-aos="fade-left"> <img class="d-block w-100" style="display: block;
            margin: 5px; border-radius: 5px; " src="../assets/img/ogolne8.jpg">
<!-- 
          <p style="text-align: justify;"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
            ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum."
          </p> -->
        </div>
      </div>
    </div>
     <!-- 16:9 aspect ratio -->
  <div class="embed-responsive embed-responsive-16by9" style="width: 50%;margin: 0 auto">
    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Qo0dmw4nyg0"></iframe>
  </div>
  </div>




  <!-- 1. The <iframe> (and video player) will replace this <div> tag. -->


  <script>
    // 2. This code loads the IFrame Player API code asynchronously.
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    var player;

    function onYouTubeIframeAPIReady() {
      player = new YT.Player('player', {
        height: '390',
        width: '640',
        videoId: 'Qo0dmw4nyg0',
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    }

    // 4. The API will call this function when the video player is ready.
    function onPlayerReady(event) {
      //event.target.playVideo();
    }

    // 5. The API calls this function when the player's state changes.
    //    The function indicates that when playing a video (state=1),
    //    the player should play for six seconds and then stop.
    var done = false;

    function onPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING && !done) {
        setTimeout(stopVideo, 6000);
        done = true;
      }
    }

    function stopVideo() {
      player.stopVideo();
    }
  </script>
  <script src="" async defer></script>
