<section class="wrapper" style="margin-top: 12%">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div
          class="card text-white card-has-bg click-col"
          style="
            background-image: url('https://source.unsplash.com/600x900/?sailboat,water');
          "
        >
          <img
            class="card-img d-none"
            src="https://source.unsplash.com/600x900/?sailboat,water"
            alt="Goverment Lorem Ipsum Sit Amet Consectetur dipisi?"
          />
          <div class="card-img-overlay d-flex flex-column">
            <div class="card-body">
              <small class="card-meta mb-2"
                >Forma Rejestracyjna / Registration Form</small
              >
              <h4 class="card-title mt-0">
                <a
                  class="text-white"
                  href="https://form.jotform.com/242403200281237"
                  target="_blank"
                  >Forma Rejestracyjna na rok Harcerski / Permission Form ↓</a
                >
              </h4>
              <small><i class="far fa-clock"></i>2024-2025</small>
            </div>
            <div class="card-footer">
              <div class="media">
                <img
                  class="mr-3 rounded-circle"
                  src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/male-512.png"
                  alt="Generic placeholder image"
                  style="max-width: 50px"
                />
                <div class="media-body">
                  <h6 class="my-0 text-white d-block">Michal Zabinski, phm.</h6>
                  <small>Szczepowy </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
