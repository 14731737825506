import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../user.service';
import * as internal from 'events';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  // @HostListener('window:scroll', ['$event'])
  // handleScroll(event) {
  //   this.scrollDepth = (
  //     (window.pageYOffset / window.innerHeight) *
  //     100
  //   ).toString();
  //   console.log(this.scrollDepth);
  // }
  //Uses vars
  users;
  isAdmin;

  activeClass = 'nav-item active custom-nav-item';
  nonActiveClass = 'nav-item custom-nav-item';

  scrollDepth: String = '10';
  glowna: String;
  ogolneInformacje: String;
  zbiorki: String;
  kontakt: String;
  formy: String;
  public isMenuCollapsed: Boolean = true;

  //Is logged in will detect whether or not the user is logged in (toggles login/logout button)
  isLoggedIn: Boolean = false;

  //Constructor
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  //Sending the user to the logout page after clicking logout button
  logout() {
    this.authService.logout();
    this.isLoggedIn = false;
    this.isAdmin = false;
    this.router.navigate(['login']);
  }

  //Sending the user to the login page after clicking login button
  login() {
    this.authService.logout();
    this.isLoggedIn = false;
    this.router.navigate(['login']);
  }

  //Sending the user to the update password page, provided the user is logged in, otherwise a popup appears
  updatePassword() {
    if (!localStorage.getItem('email')) {
      window.alert('Login to change your password');
    } else {
      this.router.navigate(['updatePassword']);
    }
  }

  //Navigating to the privacy page
  navigateToPrivacyPolicy() {
    this.router.navigate(['privacy']);
  }

  //Navigating to the privacy page
  navigateToZbiorki() {
    this.resetClasses();
    this.zbiorki = this.activeClass;
    this.router.navigate(['zbiorki']);
    this.isMenuCollapsed = true;
  }

  navigateToOgolne() {
    this.resetClasses();
    this.ogolneInformacje = this.activeClass;
    this.router.navigate(['ogolne'], { skipLocationChange: true });
    this.isMenuCollapsed = true;
  }

  navigateToFormy() {
    this.resetClasses();
    this.formy = this.activeClass;
    this.router.navigate(['formy'], { skipLocationChange: true });
    this.isMenuCollapsed = true;
  }
  navigateToKontakt() {
    this.resetClasses();
    this.kontakt = this.activeClass;
    this.router.navigate(['kontakt'], { skipLocationChange: true });
    this.isMenuCollapsed = true;
  }
  //Navigating to the main page
  navigateToMain() {
    this.resetClasses();
    this.glowna = this.activeClass;
    this.router.navigate(['']);
    this.isMenuCollapsed = true;
  }
  //Initializing the header by determining if the user is logged in and if they are an admin
  ngOnInit(): void {
    this.resetClasses();
    if (localStorage.getItem('id_token')) {
      this.isLoggedIn = true;
    }
    // this.userService.isAdmin().subscribe(
    //   (data) => {
    //     this.isAdmin = data;
    //   },
    //   (error) => {
    //     console.log('This user is not an admin');
    //   }
    // );
    // this.userService.getUsers().subscribe(
    //   (data) => {
    //     this.users = data;
    //   },
    //   (error) => {
    //     console.log('Unable to get the users');
    //   }
    // );
  }
  resetClasses() {
    this.glowna = this.nonActiveClass;
    this.ogolneInformacje = this.nonActiveClass;
    this.zbiorki = this.nonActiveClass;
    this.kontakt = this.nonActiveClass;
    this.formy = this.nonActiveClass;
  }
}
