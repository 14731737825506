import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ogolne',
  templateUrl: './ogolne.component.html',
  styleUrls: ['./ogolne.component.scss'],
})
export class OgolneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
