import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zbiorki',
  templateUrl: './zbiorki.component.html',
  styleUrls: ['./zbiorki.component.css']
})
export class ZbiorkiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
