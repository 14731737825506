import { Component, OnInit } from '@angular/core';
import {UserService} from '../../user.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {

  //Storing the password
  password;

  //Creating instance of user service to handle password input
  constructor(private userService: UserService) { }

  ngOnInit(): void {
  }

  //Geting the password from the user
  handlePassword(term: string): void {this.password = term.replace(/[<={}()>/\\]/gi, "")}


  //Sending update request to the backend
  handleUpdatePassword()
  {
    this.userService.updatePassword( localStorage.getItem("email"),this.password).subscribe(user=>{
    },
    error=>{
      console.log(error, "has occurred!")
    })
  }

}
