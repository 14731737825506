import { Injectable, ɵSWITCH_COMPILE_INJECTABLE__POST_R3__ } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {schedule} from '../schedule';
import {environment} from "../../environment"
import {Observable} from 'rxjs';
import { scheduleElement } from 'src/scheduleElement';
const baseUrl = environment.backend_url;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient)

  { }

  isAdmin(){
    const currentUsername = localStorage.getItem("email");
    const hash = localStorage.getItem("hash")
    //Getting all available course codes
    return this.http.put(baseUrl+"/admin/validate", {username:currentUsername, password:hash})
  }

  getUsers(){
     return this.http.get(baseUrl + '/admin/users');
  }

  makeAdmin(usernameInput){
    return this.http.put(baseUrl+"/admin/makeAdmin/", {username:usernameInput})
  }
  deactivate(usernameInput){
    return this.http.put(baseUrl+"/admin/deactivate/", {username:usernameInput})
  }

  reactivate(usernameInput){
    return this.http.put(baseUrl+"/admin/reactivate/", {username:usernameInput})
  }


  updatePassword(currentUsername, newPassword){
    return this.http.put(baseUrl+"/update_password/", {user:currentUsername, password:newPassword})

  }






}
