
<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light fadein">
  <img class="logo-big logo-small" src="../assets/img/apple-touch-icon.png" alt="First slide">
    <a class="navbar-brand brand-small" (click)="navigateToMain()" data-toggle="tooltip" title="Witamy">  Szczep Wodny Bałtyk</a>


<button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
    <ul class="navbar-nav">
      <li [className]= 'glowna'>
        <a class="nav-link" [routerLink]="'/zbiorki'" (click) = "navigateToMain()" style = "cursor:pointer;" >Główna</a>
      </li>
      <li  [className]= 'ogolneInformacje'>
        <a class="nav-link" (click) = "navigateToOgolne()"  style = "cursor:pointer;" >Ogólne</a>
      </li>
      <li [className]= 'zbiorki'>
        <a class="nav-link" (click) = "navigateToZbiorki()"  style = "cursor:pointer;" >Zbiórki</a>
      </li>
      <li [className]= 'kontakt'>
        <a class="nav-link"(click) = "navigateToKontakt()" style = "cursor:pointer;"  >Kontakt</a>
      </li>
      <li [className]= 'formy'>
        <a class="nav-link"(click) = "navigateToFormy()" style = "cursor:pointer;"  > Formy</a>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav icons">
    <li class="nav-item active">
        <a href="https://www.instagram.com/szczepwodnybaltyk/"><i class="icon ion-social-instagram-outline nav-item nav-link"></i></a>
    </li>
    <li class="nav-item active">
        <a href="https://www.facebook.com/SzczepWodnyBaltyk"><i class="icon ion-social-facebook nav-item nav-link"></i></a>
    </li>
    <li class="nav-item active">
        <a href="https://www.youtube.com/c/SzczepWodnyBaltyk"><i class="icon ion-social-youtube-outline nav-item nav-link"></i></a>
    </li>
    <li class="nav-item active">
        <a href="https://www.flickr.com/photos/baltyk/albums"><i class="icon ion-android-camera nav-item nav-link"></i></a>
    </li>
</ul>
<p><ngb-progressbar type="primary" [value]="scrollDepth"></ngb-progressbar></p>

</nav>



