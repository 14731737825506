<h1>PRIVACY POLICY</h1>
<div *ngIf = isAdmin><h3>Viewing as an admin</h3>
  <h1 [ngStyle]="{position:'fixed', background:'purple', left:'50%', float:'right', display: 'block'}">Admin View</h1>

  <div *ngIf = !policyExists>
    <button (click) = "createPolicy()" >Create a privacy policy</button>
  </div>
  <div *ngIf = policyExists>
    <button (click)= "showInputBox()" >Edit Privacy Policy</button>
    <div *ngIf = inputBox>
      <input maxlength = "1000"  #inputBox id="search-box" (input)="handleNewPolicy(inputBox.value)" placeholder="Enter your new policy" />
    <button (click) = "editPolicy()" >Confirm</button>
    </div>
  </div>


  <div *ngIf = !dmcaPolicyExists>
    <button (click) = "createDMCA()" >Create a DMCA policy</button>
  </div>
  <div *ngIf = dmcaPolicyExists>
    <button (click)= "showDMCAInputBox()" >Edit DMCA </button>
    <div *ngIf = DMCAinputBox>
      <input maxlength = "1000" #inputBoxDMCA id="search-box" (input)="handleNewDMCAPolicy(inputBoxDMCA.value)" placeholder="Enter your new DMCA" />
    <button (click) = "editDMCA()" >Confirm</button>
    </div>
  </div>

  <div *ngIf = !aupPolicyExists>
    <button (click) = "createAUP()" >Create a DMCA policy</button>
  </div>
  <div *ngIf = aupPolicyExists>
    <button (click)= "showAUPInputBox()" >Edit AUP </button>
    <div *ngIf = AUPinputBox>
      <input maxlength = "1000" #inputBoxAUP id="search-box" (input)="handleNewAUPPolicy(inputBoxAUP.value)" placeholder="Enter your new AUP" />
    <button (click) = "editAUP()" >Confirm</button>
    </div>
  </div>


  </div>

<p>Please read, understand and follow the terms outlined in the policies below. Negligence of the policy or intentional failure to comply with them could lead to termination of your account!</p>
<h2>Privacy Policy</h2>
<p>{{currentPolicy}}</p>
<h2> DMCA notice & Takedown policy</h2>
<h5>Contact administrtator@uwosystem.com to dispute any disagreements</h5>
<a href= {{dmcaLink}}> Click to view policy</a>
<h2>Acceptable Use Policy</h2>
<a href={{uapLink}}>Click to view policy</a>




