
    <div class="team-grid" style="margin: 80px 0 0;">
      <div class="container" data-aos="fade-up" data-aos-anchor-placement="center-center">
          <div class="intro">
              <h2 class="text-center">Zbiórki

              </h2>


          </div>
      </div>
  </div>

  <hr style="width: 40%;">
  <div>
      <div class=" container ">
          <div class="row ">
              <div class="col-md-7">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.61984473399!2d-79.49825314860192!3d43.614456362850085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3620c3330af7%3A0x2d4495456892f770!2sSt.%20Leo%20Catholic%20Elementary%20School%2C%20Mimico!5e0!3m2!1sen!2sca!4v1610141566689!5m2!1sen!2sca"
                      width="600" height="450" frameborder="0" style="border:12;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

              </div>

              <div class="col-md-5" data-aos="zoom-out">
                  <h4 style="text-align: center;">Drużyna 13sta</h4>

                  <p style=" text-align: justify; "> 13-ta Żeglarska Drużyna Harcerska im. Jana Sobieskiego III jest prowadzona przez Druha Pwd Michał Chachura. Zbiórki się odbywają we czwartki od 18:30 do 20:30 w St. Leo Catholic School, na 165 Stanley Avenue, Toronto.</p>
                  <img class="d-block w-25" style="display: block;
                  margin: 0 auto; border-radius: 5px; " src="../assets/img/13sta.jpg">
              </div>

          </div>
      </div>
  </div>
  <div style="margin-top: 20px;">
      <div class=" container ">
          <div class="row ">
              <div class="col-md-7">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.6208743880266!2d-79.72453194860233!3d43.593612664196804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b41cb69146f43%3A0x1a7f25a10ed617c3!2sRay%20Underhill%20Public%20School!5e0!3m2!1sen!2sca!4v1610142040089!5m2!1sen!2sca"
                      width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

              </div>

              <div class="col-md-5" data-aos="zoom-out">
                  <h4 style="text-align: center;">Drużyna 16sta</h4>

                  <p style=" text-align: justify; "> 16-ta Żeglarska Drużyna Harcerska im. Bolesława Chrobrego dla starszych harcerzy (wieku 14-16) jest prowadzona przez Druha HO Eric Kogut. Zbiórki się odbywają we wtorki od 18:00 do 20:00 w Ray Underhill Public School, na 32 Suburban
                      Drive, Mississauga.</p>
                  <img class="d-block w-25" style="display: block;
                margin: 0 auto; border-radius: 5px; " src="../assets/img/16sta.jpg">
              </div>

          </div>
      </div>
  </div>

  <div style="margin-top: 20px;">
      <div class=" container ">
          <div class="row ">
              <div class="col-md-7">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.6208743880266!2d-79.72453194860233!3d43.593612664196804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b41cb69146f43%3A0x1a7f25a10ed617c3!2sRay%20Underhill%20Public%20School!5e0!3m2!1sen!2sca!4v1610142040089!5m2!1sen!2sca"
                      width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

              </div>

              <div class="col-md-5" data-aos="zoom-out">
                  <h4 style="text-align: center;">Drużyna 35ta</h4>

                  <p style=" text-align: justify; ">35-ta Żeglarska Drużyna Harcerska im. Zygmunta Augusta dla młodszych harcerzy (wieku 11-13) jest prowadzona przez Druha HO Alex Jurak. Zbiórki się odbywają we wtorki od 18:00 do 20:00 w Ray Underhill Public School, na 32 Suburban Drive,
                      Mississauga.
                  </p>
                  <img class="d-block w-25" style="display: block;
              margin: 0 auto; border-radius: 5px; " src="../assets/img/35ta.png">
              </div>

          </div>
      </div>
  </div>

  <div style="margin: 20px 0px 20px;">
      <div class=" container ">
          <div class="row ">
              <div class="col-md-7">
                  <img class="d-block w-100" style="display: block;
                margin: 0 auto; border-radius: 5px; " src="../assets/img/zbiorki5.jpg">
              </div>

              <div class="col-md-5" data-aos="zoom-out">
                  <h4 style="text-align: center;">Drużyna 3cia</h4>

                  <p style=" text-align: justify; "> 3-cia Żeglarska Drużyna Wędrownicza im. Władyslawa Wagner jest prowadzona przez Druha Phm. Michal Zabinski. Obecnie są regularne zbiorki, wycieczki, wędrówki i biwaki. Drużyna już mocno zaczęła pracować i planuje mieć zbiorki w tym
                      roku harcerskim co miesiąc.
                  </p>
                  <img class="d-block w-25" style="display: block;
            margin: 0 auto; border-radius: 5px; " src="../assets/img/3cia.jpg">
              </div>

          </div>
      </div>
  </div>
