import { Component, OnInit } from '@angular/core';
import {UserService} from "../../user.service";
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit {

  //Getting storing the variables for the variables for the admin to view
  users;
  usersAdmin;
  usersAreAdmin = [];
  isAdmin;

  //Using user service to handle the user reqs from teh admin
  constructor(private userService : UserService) { }


  //Determining if the user is an admin and getting the user for an admin
  ngOnInit(): void {
    this.userService.isAdmin().subscribe((data) =>{
      this.isAdmin = data;

    },
    error=>{console.log("This user is not an admin")})
    this.userService.getUsers().subscribe((data) =>{
        this.users = data;

        //console.log(data, "is the data")
    },
    error=>{console.log("Unable to get the users")}
    );

  }


  //When the user clicks on a user they are added as an admin
  makeAdmin(input){
    this.userService.makeAdmin(input.username).subscribe((data) =>{

      //Getting teh users and what not after
      this.isAdmin = data;
      this.userService.getUsers().subscribe((data) =>{
      this.users = data;

    },
    error=>{console.log("Unable to get the users")}
    );

    },
    error=>{console.log("This user is not an admin")})

  }

  //Deactivating a user (code -2) if the admin thinks its rite
  deactivate(input){
    this.userService.deactivate(input.username).subscribe((data) =>{
      this.isAdmin = data;

      this.userService.getUsers().subscribe((data) =>{
        this.users = data;

        //console.log(data, "is the data")
    },
    error=>{console.log("Unable to get the users")}
    );

    },
    error=>{console.log("This user is not an admin")})

  }



  //Reactivating the user upon admin request
  reactivate(input){
    this.userService.reactivate(input.username).subscribe((data) =>{

      //Reloading existing data
      this.isAdmin = data;
      this.userService.getUsers().subscribe((data) =>{
        this.users = data;
    },
    error=>{console.log("Unable to get the users")}
    );

    },
    error=>{console.log("This user is not an admin")})

  }


  //Determining if a given user is an adming
  //NO LONGER USING THIS!!!!!!!!!!!
  generateIsAdmin(input){
    input.forEach(user=>{
      if(user.accountType == "admin"){
        console.log(user.username, "is an admin ")
        this.users.accountType =  (true)
      }
      else{
        this.users.accountType =  (false)
      }
    })


  }

}
