import { Component } from '@angular/core';

@Component({
  selector: 'ngbd-toast-prevent-autohide',
  templateUrl: './main-toast.component.html',
})
export class MainToastComponent {
  show = false;
  autohide = true;
}
