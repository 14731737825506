import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AuthInterceptor } from './auth.interceptor';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './_Auth/login/login.component';
import { RegisterComponent } from './_Auth/register/register.component';
import { AuthService } from './auth.service';
import { HeaderComponent } from './_Common/header/header.component';
import { ViewUsersComponent } from './_Auth/view-users/view-users.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePasswordComponent } from './_Auth/update-password/update-password.component';
import { PrivacyComponent } from './_Pages/privacy/privacy.component';
import { HomepageComponent } from './_Pages/homepage/homepage.component';
import { HistoriaComponent } from './_Pages/historia/historia.component';
import { ObozyComponent } from './_Pages/obozy/obozy.component';
import { ZbiorkiComponent } from './_Pages/zbiorki/zbiorki.component';
import { OgolneComponent } from './_Pages/ogolne/ogolne.component';
import { FormyComponent } from './_Pages/formy/formy.component';
import { KontaktComponent } from './_Pages/kontakt/kontakt.component';
import { FooterComponent } from './_Common/footer/footer.component';
import { MainToastComponent } from './_Common/main-toast/main-toast.component';

const myRoutes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'zbiorki', component: ZbiorkiComponent },
  { path: 'obozy', component: ObozyComponent },
  { path: 'ogolne', component: OgolneComponent },
  { path: 'historia', component: HistoriaComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'formy', component: FormyComponent },
  { path: 'kontakt', component: KontaktComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    ViewUsersComponent,
    UpdatePasswordComponent,
    PrivacyComponent,
    HomepageComponent,
    HistoriaComponent,
    ObozyComponent,
    ZbiorkiComponent,
    OgolneComponent,
    FormyComponent,
    KontaktComponent,
    FooterComponent,
    MainToastComponent,
  ],
  exports: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(myRoutes, { useHash: true }),
    NgbModule,
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
