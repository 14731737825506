<div id="fb-root"></div>
<script
  async
  defer
  crossorigin="anonymous"
  src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v11.0"
  nonce="HmaXjWan"
></script>
<div
  class="fb-page"
  data-href="https://www.facebook.com/szczepwodnybaltyk"
  data-width="340"
  data-hide-cover="false"
  data-show-facepile="true"
></div>

<div class="row mobile-container">
  <div class="col-md-4">
    <div class="list-unstyled">
      <h3 class="mt-0 mb-1 title">Szczep Wodny Bałtyk</h3>
      <li class="media">
        <img src="../assets/img/genericIcon.JPG" class="rounded-circle icon" />
        <div class="media-body">
          <!-- <h5 class="mt-0 mb-1">Szczep</h5> -->

          <h6 class="mt-0 mb-1">Aleksander Zabinski, Szczepowy</h6>
          <p class="mt-0 mb-1">szczep.wodny.baltyk@gmail.com</p>
          <p class="mt-0 mb-1">+1 416-540-2442</p>
        </div>
      </li>
    </div>
  </div>

  <div class="col-sm-4">
    <div class="list-unstyled">
      <h3 class="mt-0 mb-1 title" style="margin-top: 2px">
        Koło Przyjaciół Harcerstwa
      </h3>

      <li class="media">
        <img src="../assets/img/genericIcon.JPG" class="rounded-circle icon" />
        <div class="media-body">
          <!-- <h5 class="mt-0 mb-1">KPH</h5> -->
          <h6 class="mt-0 mb-1">Darek Dufaj</h6>
          <p class="mt-0 mb-1">bramptonkph@gmail.com</p>
          <p class="mt-0 mb-1">+1 416-452-2633</p>
        </div>
      </li>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="list-unstyled">
      <h3 class="mt-0 mb-1 title" style="margin-top: 2px">Szczep Zarzewie</h3>

      <li class="media">
        <img src="../assets/img/genericIcon.JPG" class="rounded-circle icon" />
        <div class="media-body">
          <!-- <h5 class="mt-0 mb-1">KPH</h5> -->
          <h6 class="mt-0 mb-1">Szczepowa Szczepu Zarzewie (Harcerki)</h6>
          <p class="mt-0 mb-1">zarzewie@watra.zhpkanada.org</p>
        </div>
      </li>
    </div>
  </div>
  <div class="col-sm-4" style="padding-left: 1%">
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSzczepWodnyBaltyk%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
      width="300"
      height="550"
      style="border: none; overflow: hidden; left: 0"
      scrolling="no"
      frameborder="0"
      allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
  </div>
</div>
