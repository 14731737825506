import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formy',
  templateUrl: './formy.component.html',
  styleUrls: ['./formy.component.scss'],
})
export class FormyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
