
<div *ngIf="isAdmin">
<h1 [ngStyle]="{position:'fixed', background:'purple', left:'50%', float:'right', display: 'block'}">Admin View</h1>

<ul >
  <li *ngFor="let user of users">
    <h2>{{user.name}}</h2>
    <div *ngIf = "user.accountType==-2">
      <button>DEACTIVATED</button>
    </div>


    <div *ngIf = "user.accountType!=='admin'">
      Make  {{user.name}} admin
       <input  type="checkbox" (click) = "makeAdmin(user)">
      </div>
    <div *ngIf = "user.accountType!== -2 ">
      Deactivate  {{user. name}}
        <input  type="checkbox" (click) = "deactivate(user)">
      </div>

    <div *ngIf = "user.accountType==-2">

        Reactivate  {{user.name}}
          <input  type="checkbox" (click) = "reactivate(user)">
      </div>

</li>
</ul>
</div>
