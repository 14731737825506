<!-- Footer -->
<footer>

  <!-- Footer Elements -->
  <div class="container">

    <!-- Grid row-->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 py-5">
        <div class="mb-5 flex-center">

        </div>
      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row-->
  </div>

    <!-- Copyright -->
   
  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2021 Copyright
    <a href="https://SzczepWodnyBaltyk.com"> SzczepWodnyBaltyk.com</a>
  </div>

</footer>
<!-- Footer -->
