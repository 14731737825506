import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import {environment} from '../../../../environment'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  //Contructor
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router
  ) { }

  //This will store what the user has inputted
  private username:string;
  private password :string;
  baseURL = environment.backend_url;


  //Moving the user to register if they click register
  handleRegister(){
    this.router.navigate(['register']);
  }


  //Init (empty for now)
  ngOnInit(): void {
  }


  //Getting the user input from the various input boxes
  handleUsername(term: string): void {this.username = term.replace(/[<={}()>/\\]/gi, "")}
  handlePassword(term: string): void {this.password = term.replace(/[<={}()>/\\]/gi, "")}

  //Handling the input and getting a rsponse
  handleLogin(){
    if(this.username == ""){
    }
      const headers = new HttpHeaders({'Content-type': 'application/json',
    });
      //Creatibg the request object
      const reqObject = {
        username: this.username,
        password: this.password
      };

      //Sneding the request to login
      this.http.post(this.baseURL+'/login', reqObject, { headers: headers }).subscribe(

        // The response data
        (response) => {

          // If the user authenticates successfully, we need to store the JWT returned in localStorage
          this.authService.setLocalStorage(response);

        },
    (error) => {

      //Error if the user is deactivated
      if(error.status == 423){
        window.alert("Sorry, your account has been deactivaed. Contact a system admin <3")
      }

      //Error if the user has not activated their own email
      else if(error.status == 403){
        window.alert("Account is not activated. Visit"+environment.backend_url+"/verify/"+this.username+ " to register your account")
      }

      //Error if the user has not entered the right usern, password combo
      else if(error.status == 401){
        window.alert("Sorry, no user with those credentials exists");
      }

    },

    // When observable completes
    () => {
      console.log('done!');
        this.router.navigate(['main']);
    }

  );
}


}
