
import { Injectable } from '@angular/core';
import * as moment from "moment";

@Injectable()
export class AuthService {

    constructor() {}

    setLocalStorage(responseObj) {
       //We receive the JWT and we want to put it in the local storage
        const expiresAt = moment().add(responseObj.expiresIn);
        const currentEmail =  responseObj.email;
        const currentHash = responseObj.hash;

        //We need to set the local storea
       //Items are set as ('key', value)
        localStorage.setItem('id_token', responseObj.token);
        localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );

        localStorage.setItem("email", (currentEmail))
        localStorage.setItem("hash", (currentHash))
    }

        //Removing the items from the local storage
       //Angular will look to see if it can find these and if it can't it will tell the user to login again
    logout() {

        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("email");
        localStorage.removeItem("hash");
    }


    //Determining if a user is still logged in based on when their token expries
    public isLoggedIn() {
        //If it returns true, the JWT is valid and we can keep the user logged in
        return moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }


    //Getting the expiration of a user
    getExpiration() {
      //This is what checks if a user is logged in
      const expiration = localStorage.getItem("expires_at");
      //Making it a JS object
      const expiresAt = JSON.parse(expiration);

      //Calculating the point in time when the JWT expires at
      return moment(expiresAt);

    }
}
